// Messaging
// Base message to match how OnSight Core API's work
import { Content } from "@tiptap/core";

// Extend this interface for adding objects
export interface MessageBus {
  success: boolean;
  message: string | null;
  item?: any;
}

// Forms
export interface LoginForm {
  email: string;
  password: string;
}

export enum RoleType {
  SLSAdmin = "SLSAdmin",
  SLSClubAdmin = "SLSClubAdmin",
  DutyOfficer = "DutyOfficer",
  PatrolCaptain = "PatrolCaptain",
  TabletUser = "TabletUser",
  WeatherUser = "WeatherUser",
  CoastGuard = "CoastGuard"
}

export enum ReportType {
  DailyPatrol = "DailyPatrol",
}

// Users
export interface AdminUser {
  id: string;
  SLSNZNumber: string;
  club: string;
  patrolableClubIDs?: string[];
  pendingClubIDs?: string[];
  currentPatrolId?: string;
  currentPatrolDate?: Date;
  regions: string[];
  clubId: string;
  email: string;
  phoneNumber?: string;
  secondaryPhoneNumber?: string;
  firebaseUID: string;
  lastSignIn?: Date;
  firstName: string;
  lastName: string;
  roleType: RoleType;
  type: string;
  developer?: boolean;
  registering?: boolean;
  dailyPatrolReports?: boolean;
  dailyPatrolReportClubs?: string[];
  dailyRegionalReports?: boolean;
  dailyRegions?: string[];
  vesselNotificationReports?: boolean;
  vesselRegions?: boolean;
  isSARCoordinator?: boolean;
  memberId?: string;
  lifeguardCRMId?: string;
  sendEventEmail?: boolean;
  sendSAREmail?: boolean;
  allShortCodes?: boolean;
  allIncidentClubs?: boolean;
}

export interface AdminDevice {
  id: string;
  name: string;
  clubId: string;
  club: string;
  deleted?: boolean;
  disabled?: boolean;
  passPhrase: string | null;
  email: string;
  password: string;
}

// Clubs
export interface Club {
  id: string;
  CRMGUID?: string;
  region?: Region;
  active?: boolean;
  displayName: string;
  safeswimId?: string;
  shortCode?: string;
  displayOrder: number;
  archived?: boolean;
  name: string;
  location: LatLon;
  weatherLocation?: LatLon;
  ripLocation?: LatLon;
  ripWarningActive?: boolean;
  ripWarningModel?: RipWarningModel;
  windWarningModel?: WindWarningModel;
  locations?: string[];
  commonLocations?: FBLocation[]; // FeatureCollection;
  patrolAreas?: FBLocation[]; // FeatureCollection;
  depth?: number;
  oraFormActive?: boolean;
  beachAngle?: number;
  beachType?: string;
  cmar?: number;
  averageLowTide?: number;
  averageHighTide?: number;
}

export enum RipWarningModel {
  TideModified = "TideModified",
  WaveDominated = "WaveDominated",
}

export enum WindWarningModel {
  WestCoast = "WestCoast",
  EastCoast = "EastCoast",
}

export enum Region {
  Northern = "Northern",
  Eastern = "Eastern",
  Central = "Central",
  Southern = "Southern",
}

export interface FBLocation {
  name: string;
  type: string;
  geometry: FBGeometry[];
}

export interface FBGeometry {
  x: number;
  y: number;
}

// GPS
export interface LatLon {
  lat: number;
  lon: number;
}

export interface Geofence {
  geometry: LatLon[];
  name: string;
  center: LatLon;
}

export interface SLSNRMember {
  id?: string;
  awards?: Award[];
  club?: string;
  firstName?: string;
  lastName?: string;
  memberId?: string;
}

export interface Award {
  date?: Date | null;
  state: AwardState;
  level?: string;
  name: string;
  image: string;
}

export enum AwardState {
  CURRENT,
  EXPIRED,
  NEVER,
}

// Lifeguard
export interface LifeGuard {
  // Firebase
  firebaseID: string;
  club: string;
  clubID: string;
  updatedAt: Date | null; // This allows us to remove a member if they weren't updated
  removed: boolean;
  // Dynamics
  CRMClubID: string;
  CRMContactId: string;
  CRMMembershipId: string;
  season: string;
  // General
  firstname: string;
  lastname: string;
  slsnzIDNumber: string;
  age: number;
  // Awards
  cims: Date | null;
  cimslevel: string;
  firstaidlevel: Date | null;
  firstaidlevellevel: string;
  irblevel: string;
  irbcrewpersonsmodule: Date | null;
  vhfoperator: boolean;
  painmanagementmodule: Date | null;
  patrollingmember: boolean;
  patrolsupport: Date | null;
  rescuewatercraftoperatorsaward: Date | null;
  rocktrainingrescuemodule: Date | null;
  seniorlifeguardawardirbdriver: Date | null;
  seniorlifeguardawardpatrolcaptain: Date | null;
  surffirstresponder: Date | null;
  surflifeguardaward: Date | null;
  surflifeguardawardlevel: string;
  surffirstaid: Date | null;
  surffirstaidlevel: string | null;
  twoHundredMeterSafetyBadge: Date | null;
}

export interface LifeGuardMin {
  firstName: string;
  lastName: string;
  club: string;
  clubID: string;
  memberID: string;
  role: string;
  firebaseID?: string;
  responseStatus?: SARNotificationResponse,
  eta?: number | null,
  etaRecordedAt?: Date | null,
  awards?: Award,
  locationLat?: number | null,
  locationLong?: number | null,
  createdAt?: Date,
  updatedAt?: Date,
  userId?: string,
  user?: AdminUser,
  lifeguardWithAwards?: LifeGuard,
  removed?: boolean,
  patrolId?: string
}

export enum Problem {
  SAR = "SAR",
  RESCUE = "Rescue",
  MAJORFIRSTAID = "MajorFirstAid",
  MINORFIRSTAID = "MinorFirstAid",
  ASSIST = "Assist",
  SEARCH = "Search",
  FIRE = "Fire",
  DISTRURBANCE = "Disturbance",
  RECOVERY = "Recovery",
}

// Incident
export interface Incident {
  club: string;
  clubID: string;
  duringPatrol?: boolean;
  detailsStarted: boolean;
  createdBy?: string;
  id: string;
  pCReportId?: string;
  incidentClosed: boolean;
  closedBySurfCom?: boolean;
  incidentCancelled: boolean;
  incidentTitle?: string;
  problemDescription: string | null;
  incidentDescription: string | null;
  lifeguards?: LifeGuardMin[];
  milestones: MileStone[];
  patients: PatientMinDto[];
  peopleNumber: number;
  positionCommonName: string;
  primaryLocation?: string;
  positionLat: number | null;
  positionLng: number | null;
  problem: Problem | null;
  progress: number;
  requestEventClosed: boolean | null;
  startTime: Date | string;
  reportedStartTime: Date;
  reportedEndTime: Date;
  // Agencies
  policeAgency?: ExternalAgency;
  rccnzAgency?: ExternalAgency;
  coastguardAgency?: ExternalAgency;
  stjohnAgency?: ExternalAgency;
  informantMemberOfPubic?: MemberOfPublic;
  informant: any[];
  externalAgency?: ExternalAgency;
  // Activities Involved
  swimming?: boolean;
  surfingBodyboard?: boolean;
  sailOrBoat?: boolean;
  walkingRunning?: boolean;
  fishing?: boolean;
  attemptRescue?: boolean;
  activityOther?: string;
  // Resources used
  irb?: boolean;
  rescueTube?: boolean;
  rescueBoard?: boolean;
  rescueVehicle?: boolean;
  radios?: boolean;
  rwc?: boolean;
  firstAidEquipment?: boolean;
  noEquipment?: boolean;
  methoxyflurane?: boolean;
  resourceOther?: string;
  ripHole?: boolean;
  strongSurf?: boolean;
  strongWinds?: boolean;
  equipmentFailure?: boolean;
  insectSting?: boolean;
  jellyFishSting?: boolean;
  // Other
  code?: number;
  color?: string;
  slsnzWorkplaceInjury?: boolean;
  followUpWithPeerSupport?: boolean;
  // Sar Team
  sARTeam?: SARTeam;
  rallyStreet?: string,
  rallyCity?: string,
  rallySuburb?: string,
  rallyPostcode?: string,
  sARMessage?: string,
}

export interface ExternalAgency {
  name: string;
  agency: string;
  externalRef?: string;
  costCentre: string;
  contactDetailsMessage: string;
  eta: string;
  arrival: string;
}

export interface MemberOfPublic {
  name: string;
  relationship: string;
  contactDetails: string;
  informantPosition: string;
  pointLastSeen: string;
  lastKnownPosition: string;
  timeLastSeen?: Date;
  seenByWhom: string;
  patientActivity: string;
  cirumstances: string;
}

// Milestone
export interface MileStone {
  description: string;
  name: string;
  timeOfMilestone: Date;
}

// Message Log
export interface MessageLog {
  loggedBy: string;
  message: string;
  time: Date;
  type: string;
}

export interface Communication {
  id: string;
  time: Date;
  surfcomOperator?: string;
  deltaOne?: string;
  deltaTwo?: string;
  alphaOneOperators?: string[];
  surfComOperators?: string[];
  roles: CommsRole[];
  deleted?: boolean;
  createdByWeb?: boolean;
  createdAt?: Date;
}

export interface CommsRole {
  id: string;
  role: string;
}
export interface CommunicationLog {
  authorTitle: string;
  authorId: string;
  id?: string;
  fromId?: string;
  fromName: string;
  toId?: string;
  toName?: string;
  mode?: string;
  message: string;
  messageJson?: MessageJSON | Record<string, any>;
  html: string;
  time: Date;
  incidentId?: string;
  incidentCreation?: boolean;
  type?: string;
  messageId?: string
}

export interface CommunicationMessage {
  authorTitle: string;
  authorId: string;
  id?: string;
  fromId?: string;
  fromName: string;
  toId?: string;
  toName?: string;
  mode?: string;
  message: string;
  messageJson?: MessageJSON | Record<string, any>;
  html: string;
  time: Date;
  incidentId?: string;
  incidentCreation?: boolean;
  type?: string;
  respondingMessage?: string,
  ignore?: boolean
}

export interface MessageJSON {
  type: string;
  content: Content;
}

export interface LogActions {
  id: string;
}
export interface SurfcomIncident {
  incidentId: string;
  name: string;
  color: string;
  commsId: number;
  id?: string;
  isStandby: boolean;
  isActive: boolean;
  timeAdded: Date;
}

// Patient
export interface PatientMinDto {
  age: number | null;
  equipmentUsed: string[];
  firebaseRef: string | null;
  ethnicity: PrimaryEthnicity | null;
  firstName: string | null;
  lastName: string | null;
  status?: number | null;
  personFound: boolean;
  sex: string | null;
  vitalsAvailable: boolean;
  patientIncidentLevel?: number | null;
}

export enum PrimaryEthnicity {
  Maori = "Maori",
  European = "European",
  PacificIsland = "PacificIsland",
  Asian = "Asian",
  Other = "Other",
}

export interface Patient {
  activity?: string | null;
  address?: string | null;
  age?: number | null;
  allergies?: string | null;
  city?: string | null;
  complaint?: string | null;
  country?: string | null;
  dob?: string | null;
  dobDate?: number | null;
  dobMonth?: number | null;
  dobYear?: number | null;
  equipmentUsed?: string[] | null;
  fatilityPrevented?: boolean | null;
  firstName: string;
  id: string;
  incidentId?: string | null;
  incidentLevel?: number | null;
  incidentReason: string;
  informantFirstName?: string | null;
  informantLastName?: string | null;
  informantPhoneNumbers?: string | null;
  inicidentId?: string | null;
  lastKnownPoint?: string | null;
  lastName: string;
  lastSeenByWho?: string | null;
  medicalMentalCondition?: string | null;
  medication?: string | null;
  missingPersonDescription?: string | null;
  otherEthnicOrigin?: string | null;
  patientConditions: PatientCondition[];
  patientOutcome?: string | null;
  patientVitals: Vital[];
  personFound?: boolean | null;
  phoneNumbers?: number | null;
  pointLastSeen?: string | null;
  primaryEthnicOrigin?: PrimaryEthnicity | null;
  reasonForDisapperance?: string | null;
  searchActivities: SearchActivity[];
  sex: string;
  slsnzMember?: boolean | null;
  slsnzMemberNumber: string;
  suburb?: string | null;
  treatment?: string | null;
  valueIndex?: number | null;
  valueId?: string | null;
  sARTeam?: SARTeam;
}

export interface PatientCondition {
  level: number;
  time: Date;
}

export interface Vital {
  airway: string | null;
  bloodGlucouse: string | null;
  bloodPressure: number[];
  bloodPressureStr: string;
  breathing: number;
  dose: string;
  interventionDrugs: string;
  interventionManual: string;
  o2sat: number;
  onAir: boolean;
  onAirAmount: number | null;
  pulse: number;
  responseAVPU: number;
  temp: number;
  time: Date;
}

export interface QuicktypeItem {
  command: string;
  prefix?: string;
  items: QuicktypeSuggestion[];
  description: string;
  defaultMessage?: string;
  allowCustomInput: boolean;
  customInputPrefix?: string;
  customOutputPrefix?: string;
}

export interface QuicktypeSuggestion {
  suggestion: string;
  abbreviation?: string;
}

export interface SearchActivity {
  time: Date;
  action: string;
}

// PCReport
// export interface PatrolReport {
//   submittedToCrmTime?: any;
//   submittedToCRM?: boolean;
//   id: string;
//   CRMGUID: string | null;
//   CRMClubID: string;
//   clubID: string;
//   clubName: string;
//   dateOfPatrol: Date;
//   generalNotes: string | null;
//   highTideTime: string[] | null;
//   numberOfATVs: number | null;
//   numberOfIRBsRWCs: number | null;
//   numberOfPatrolMembers: number | null;
//   numberOfPatrolledAreas: number | null;
//   numberOfPeopleAssisted: number | null;
//   numberOfPeopleMajorFirstAid: number | null;
//   numberOfPeopleMinorFirstAid: number | null;
//   numberOfPeopleRescued: number | null;
//   numberOfPeopleSearchedFor: number | null;
//   numberOfPreventativeActions: number | null;
//   numberOfPublicPreventativeAction: number | null;
//   numberOfRadios: number | null;
//   patrolEndedByPatrol: boolean | null;
//   patrolEndedBySurfComm: boolean | null;
//   otherEquipment: string[] | null;
//   patrolCaptain: LifeGuard | null;
//   patrolDescriptionNotes: string | null;
//   patrolFinishTime: Date | null;
//   primaryLocation: string;
//   patrolMembers: LifeGuard[];
//   patrolStartTime: Date | null;
//   patrolTeamName: string;
//   peakHeadCount: number | null;
//   serviceType: string | null;
//   serviceTypeOther: string | null;
//   totalHoursWorked: number | null;
//   weatherConditions: WeatherConditions | null;
// }

// PATROL STATS
export interface PatrolStat {
  id: string;
  CRMGUID: string;
  clubID: string;
  clubName: string;
  locationName: string;
  clubLocation?: LatLon;
  displayOrder?: number;
  dateOfPatrol: Date;
  generalNotes: string | null;
  ignoreReport?: boolean;
  headcounts: HeadCount[];
  highTideTime: string[] | null;
  hazards: Hazard[];
  hazardReport: SafeswimHazardReport;
  incidents: Incident[];
  isCancelled: boolean;
  numberOfATVs: number | null;
  numberOfIRBs: number | null;
  numberOfRWCs: number | null;
  numberOfIRBsRWCs: number | null;
  numberOfPatrolMembers: number | null;
  numberOfPatrolledAreas: number | null;
  numberOfPeopleAssisted: number | null;
  numberOfPeopleMajorFirstAid: number | null;
  numberOfPeopleMinorFirstAid: number | null;
  numberOfPeopleRescued: number | null;
  numberOfPeopleSearchedFor: number | null;
  numberOfPreventativeActions: number | null;
  numberOfPublicPreventativeAction: number | null;
  numberOfRadios: number | null;
  patrolLocation?: string;
  patrolLocationOther?: string;
  numberOfPeopleSearch: number | null;
  patrolEndedByPatrol: boolean | null;
  patrolEndedBySurfComm: boolean | null;
  otherEquipment: string | null;
  patrolCaptain: LifeGuard;
  patrolDescriptionNotes: string | null;
  patrolFinishTime: Date;
  primaryLocation: string;
  patrolMembers: TimeSheet[];
  patrolStartTime: Date;
  patrolTeamName: string;
  peakHeadCount: number | null;
  preventativeActions: PreventativeAction[];
  preventativeActionPeople: number;
  preventativeActionTotal: number;
  serviceType: string | null;
  serviceTypeOther: string | null;
  status?: string;
  totalHoursWorked: number | null;
  weatherConditions: WeatherConditions | null;
  selectedIncident?: string;
  submittedToCRM?: boolean;
  submittedToCrmTime?: Date;
  submittedToCRMStatus?: string;
  submittedToCRMErrorMessage?: string;
  signedInUsers: AdminUser[];
  waterQuality?: WaterQuality;
  submittedPatrolVerified?: boolean;
  submittedPatrolVerifiedTime?: Date;
  oraStatus?: ORAStatus;
  oraId?: string;
  activityType? : ActivityType;
  offshoreWindWarning?: boolean;
  largeSurfWarning?: boolean;
  ripRiskIndex?: number;
  ripHazardGenerationTime?: string;
}

export interface WeatherConditions {
  surfConditions: string;
  waveHeight: number;
  weatherDescription: string;
  windDirection: string;
  windStrength: string;
}

export interface HeadCountDto {
  id: string;
  dailyTotal: number;
  clubId: string;
  clubName: string;
  patrolId?: string;
  dateOfCount: Date;
  headCounts: HeadCount[];
}

export interface HeadCount {
  headCountTime: Date;
  inWater: number | null;
  onBeach: number | null;
  total: number | null;
}

export interface PreventativeAction {
  id: string;
  clubId: string;
  patrolId?: string;
  quickSelect: string;
  people: number;
  timeActionRecorded: Date;
  description: string;
  uniqueActions?: number;
}

// Metocean weather and tide
export interface Metocean {
  oceanWeather: OceanWeather;
  tidalData: TidalData;
}

export interface OceanWeather {
  clubId: string;
  clubName: string;
  time: string;
  airTemperature: number;
  rainfall: number;
  seaTemperature: number;
  visibility: number;
  windDirection: number;
  windSpeed: number;
  waveHeight: number;
}

export interface TidalData {
  times: string[];
  heights: number[];
  stages: string[];
}

export interface TimeSheet {
  id?: string;
  clubID: string;
  clubIDRosteredTo: string;
  clubName: string;
  clubNameRosteredTO: string;
  clubPatrolIDRosteredTo?: string;
  finishTime: Date;
  firstName: string;
  lastName: string;
  lifeguardID: string;
  lifeguardCRMGUID: string;
  patrolAttendanceID?: string;
  role: string;
  startTime: Date;
  totalHours?: number;
}

// Comms log types
export interface SafeswimHazardReport {
  actionCreated: null | boolean;
  additionalNotes: string;
  beachClosed: false;
  clubId: string;
  clubName: string;
  created: Date;
  hazards?: Hazard[];
  hazardsSubmitted?: HazardSubmit[];
  id: string;
  patrolCaptainReporting: LifeGuard;
  pcReportId: string;
  reportLocation: string;
}

export interface Hazard {
  id: string;
  optionId: string;
  optionTitle: string;
  title: string;
  status?: string;
  automated?: boolean;
}

export interface HazardSubmit {
  time: Date | null;
  sentBy: string;
  sentById: string;
  hazards: Hazard[];
  additionalNotes: string;
  beachClosed: boolean;
}

export interface CommunicationsLog {
  id: string;
  time: Date;
}

export enum CommAction {
  SAFESWIM = "SAFESWIM",
  INCIDENT = "INCIDENT",
  REDFLAG = "REDFLAG",
  CLUBREQUEST = "CLUBREQUEST",
  INCIDENT_CLOSED = "INCIDENT_CLOSED",
}

export interface IncomingComms {
  id: string;
  actionType: CommAction;
  club_i_d: string;
  club_name: string;
  time_created: Date;
  time_actioned: Date | null;
  actions: IncomingAction[];
  standby: boolean;
  data: any;
  actioned: boolean;
}

export interface IncomingAction {
  title: string;
  action: string; // List of enum actions
}

// Logging
export enum LogLevel {
  INFO = "INFO",
  WARNING = "WARNING",
  ERROR = "ERROR",
}

export enum Application {
  BEACH = "BEACH",
  ADMIN = "ADMIN",
  SERVER = "SERVER",
}

export enum DataChangeType {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export interface SystemLog {
  logType: LogLevel;
  userName: string; // System or User
  userId: string | null;
  userRole: RoleType | null;
  timeCreated: Date;
  application: Application;
  operatingSystem: string;
  ref?: string;
  taskName: string;
  documentId?: string;
  dataChanged: string;
  dataChangeType: DataChangeType;
}

export interface SignIn {
  clubId: string;
  clubName: string;
  created: Date;
}

export enum SurfIcon {
  AllVehicles,
  ATV,
  FirstAid,
  IRB,
  RWC,
  Lifeguard,
  Methoxyflurane,
  Patrol,
  Radio,
  RescueBoard,
  RescueTube,
  Swimmer
}

// Scheduler

export interface PatrolSeason {
  id?: string;
  startDate: Date;
  endDate: Date;
  season: string; // 2023/2024
}

// Sub collection
export interface PatrolDate {
  id?: string;
  safeswimId?: string; // Safeswim ID
  date: Date;
  startTime?: Date;
  endTime?: Date;
  clubId: string;
  clubName: string;
  status: PatrolDateStatus;
  publicHoliday?: string;
  note: string;
}



export enum PatrolDateStatus {
  NONE = 'NONE',
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
  AMENDING = "AMENDING",
  AMENDED = "AMENDED",
  CANCELLED = "CANCELLED",
}

export interface Feedback {
  message: string;
  time: Date;
  userType: RoleType;
  clubId: string;
  clubName: string;
  version: string;
}

export interface WaterQuality {
  id?: string;
  safeswimClubId: string;
  name: string;
  pinColor: string;
  updateTime: Date;
}

export interface AssetType {
  id?: string;
  createdAt: Date;
  updatedAt?: Date;
  version: number;
  name: string;
  hasEngine: boolean;
  customFields?: object[];
  isArchived: boolean,
}

export interface Asset {
  id?: string,
  createdAt: Date,
  updatedAt?: Date,
  identifier?: string,
  type: AssetType
  engineHours?: string,
  condition?: Enumerator,
  versionType: number,
  customField: any,
  homeClubId: string,
  homeClubName: string,
  currentClubId?: string,
  currentClubName?: string,
  isArchived: boolean,
  description?: string,
  category: Category, 
  //photo?: Photo[],

}

export interface Category{
  name: string,
 }

 export interface FormResult {
  id?: string,
  pcName: string, 
  clubName: string,
  date: Date,
  pcReportId?: string,
  completionPercentage?: string,
  submissionStatus?: string
 }
export interface SARTeam {
  id?: string,
  location?: Club,
  name: string,
  deleted?: boolean
  clubName?: string
  rallyStreet?: string,
  rallyCity?: string,
  rallySuburb?: string,
  rallyPostcode?: string,
}
 // ORA
export enum ORAStatus {
  Incomplete = "Incomplete",
  Complete = "Complete",
  NotActive = "NotActive",
}

 export interface PCReportResult {
  id?: string,
  clubName: string,
  serviceType: string,
  activityType?: string,
  pcName: string,
  date: string,
 }

 export enum ORAStatus {
  INCOMPLETE = "INCOMPLETE",
  COMPLETE = "COMPLETE",
  MODIFIED = "MODIFIED",
}

export interface ORAForm {
  id: string;
  title: string;
  version: number;
  pcReportId: string;
  createdAt: Date;
  createdById: string;
  createdByName: string;
  submittedAt: Date;
  submittedById: string;
  submittedByName: string;
  resources: ORAAnswer[];
  ora: ORAAnswer[];
  weather: ORAAnswer[];
  status?: ORAStatus;
}

export interface ORAAnswer {
  answers: string[];
  createdAt: string;
  createdById: string;
  createdByName: string;
  pcReportId: string;
  questionId: string;
  questionIndex: number;
  questionInputType: string;
  questionNaAllowed: boolean;
  questionName: string;
  questionText: string;
  required: boolean;
}

// Report
export enum Report {
  PATROLREPORT = "PATROLREPORT",
  PATROLACTIVITYORA = "PATROLACTIVITYORA",
}

export enum ActivityType {
  education = "education",
  training = "training",
  competition = "competition",
  event = "event",
}

export interface Notification {
  phoneNumber: string,
  memberName: string,
  message?: string,
  appLink?: string,
  patrolReportId?: string, //Patrol Capitan ID
  incidentId: string, //Incident ID
  lgMemberId: string, //LifeGuard ID
  lgIncidentId:string,
  //Address:
  rallyStreet?: string,
  rallyCity?: string,
  rallySuburb?: string,
  rallyPostcode?: string,
  type: NotificationType,
  processed: boolean,
  createdAt?: Date,
  isTraining?: boolean,
  subject?: string, 
  email?: string
}

export enum NotificationType {
  SMS = 'SMS',
  EMAIL = 'EMAIL',
  CALL = 'CALL',
}

export enum SARNotificationResponse {
  requested = 'requested',
  responded = 'responded',
  declined = 'declined',
  cancelled = 'cancelled',
  closed = 'closed',
  arrived = 'arrived',
  failed = 'failed'
}

export interface SARConfirmationResult {
  message: string,
  htmlMessage: string,
  sARTeamId: string,
  pcID: string,
  rallyStreet: string,
  rallyCity: string,
  rallySuburb: string,
  rallyPostcode?: string,
  isTraining: boolean
}

export interface SARInvite {
  id?: string,
  userId: string,
  club?: Club,
  clubID?: string,
  createdAt: Date,
  eta?: Date,
  firstName: string,
  incidentId: string,
  lastName: string,
  lifeguardId: string,
  locationLat?: string,
  locationLong?: string,
  memberID: string,
  responseStatus?: SARNotificationResponse,
  role: string,
  sarTeamId: string,
  sarTeamName: string,
  type: string,
  updatedAt: Date,
  isTraining?: boolean,
  }

export interface Form {
  id?: string,
  name: string,
  publishedStatus?: FormStatus,
  version: number,
  subCollections: Array<string>,
  publishedAt?: Date, 
  exporting?: boolean, 
}

export enum FormStatus {
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT"
}

export interface FromQuestion {
  id?: string,
  name: string,
  text: string,
  index: number,
  inputType: FormInputType,
  naAllowed: boolean,
  required: boolean,
  answers?: Array<string>,
}

export enum FormInputType {
  selectMultiple = "selectMultiple",
  selectOne = "selectOne",
  boolean = "boolean"
}

export interface Contact {
  id?: string,
  name: string,
  deleted: boolean,
  phoneNumber?: string,
  email?: string,
  associatedClub: string,
  type?: ContactType,
  externalId?: string
  saveContact?: boolean
}

export interface ContactGroup {
  id?: string,
  name: string, 
  createdAt: Date,
  deleted: boolean,
}

export enum ContactType {
  user = "user",
  contact = "contact",
  SARTeam = "SARTeam",
  ContactGroup = "ContactGroup",
  SARMember = 'SARMember',
  none = "none"
}

export interface InvalidCRMTask {
id?: string,
createdAt: Date,
resolved: boolean,
user: AdminUser,
oldCRMLink: LifeGuard,
newCRMLink?: LifeGuard,
userId: string
}
